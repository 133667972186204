<template>
    <div class="header">
        <img :src="info.img" alt="" />
        <div class="search-box">
            <div class="help">{{ info.title }}</div>
            <!-- <div class="search">
                <el-input placeholder="输入关键词搜索" v-model="input">
                    <img
                        class="icon prefix"
                        slot="prefix"
                        src="../../assets/support/ss.png"
                        alt=""
                    />
                    <img
                        class="icon suffix"
                        slot="suffix"
                        src="../../assets/support/hc.png"
                        alt=""
                        @click="sendValue()"
                    />
                </el-input> -->
            <!-- </div> -->
            <!-- <div class="keyword">热搜：搜索名搜索名称搜索名称 搜索名称搜</div> -->
        </div>
    </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
    props: ['info'],
    data() {
        return {
            input: ''
        };
    },
    methods: {
        sendValue() {
      this.$emit('childEvent', this.input);
    }
    },
};
</script>

<style scoped>
.header {
    position: relative;
}

img {
    width: 100%;
}
.header > img {
    width: 100%;
    height: 4rem;
}

.search-box {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%);
}
.help {
    font-size: 0.38rem;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 1rem;
    /* margin-top: .3rem; */
    margin-bottom: .3rem;
}
.search /deep/ .el-input__inner {
    border-radius: .24rem;
    width: 8rem;
    padding-left: .5rem;
}
.el-input {
     position: relative;
}
.el-input /deep/ .el-input__prefix {
    width: .22rem;
    height: .22rem;
    position: absolute;
    top: .14rem;
    left: .18rem;
    
}
.el-input /deep/ .el-input__suffix {
    width: .22rem;
    height: .22rem;
    position: absolute;
    top: .14rem;
    right: .18rem;
    
}

.keyword {
    font-size: 0.18rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    margin-top: .12rem;
    margin-left: .24rem;
}
</style>
